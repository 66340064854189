<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                              ###### -->
<!-- ###### @date: marzo 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
  <div>
    <v-dialog v-model="dialogContrarreferencia" persistent transition="dialog-bottom-transition" max-width="1000">
      <v-card style="width: 100%;">
        <v-toolbar style="position: sticky; top: 0; z-index: 1; width: 100%;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Contrarreferencia para la solicitud numero:
              {{ informacionFilaPrincipal.idSolicitud }}</v-toolbar-title>
            <v-btn icon dark @click="cerrarDialogContrarreferencia()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-progress-linear v-if="overlay" color="primary" indeterminate rounded height="6"></v-progress-linear>
        <v-overlay :value="overlay"> </v-overlay>
        <v-card-text class="pe-2 mt-6">
          <validation-observer v-slot="{ invalid }" ref="observerSolicitud">
            <v-form class="mt-3">
              <div>
                <validation-provider class="d-flex" v-slot="{ errors }" :rules="{ required: true }">
                  <div style="display: flex; margin-right: 1rem; min-width: 15rem;" class="mt-2">
                    <strong class="me-1">Seleccione los soportes de atención </strong>
                    <p style="color: red; display: inline;">*</p>
                  </div>
                  <v-file-input ref="refOtrosarchivos" v-model="formContrarreferencia.fileResultados" accept=".pdf"
                    multiple chips outlined dense prepend-icon="" label="Seleccionar archivos"
                    style="min-width: 30rem; max-width: 30rem;" :error-messages="errors">
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <div class="d-flex">
                    <v-row class="mt-0">
                      <v-col v-for="(item, i) in formContrarreferencia.fileResultados" :key="i" cols="4"
                        class="mt-0 mb-0 pt-0 pb-0 me-2">
                        <v-tooltip left color="success">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small fab icon color="blue" @click="visualizarDocumentoSubido(item)" v-bind="attrs"
                              v-on="on">
                              <v-icon>visibility</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                </validation-provider>
                <validation-provider class="d-flex" v-slot="{ errors }" :rules="{ required: true }">
                  <div style="display: flex; margin-right: 1rem; min-width: 15rem;" class="mt-2">
                    <strong class="me-1">Seleccione la factura </strong>
                    <p style="color: red; display: inline;">*</p>
                  </div>
                  <v-file-input ref="refOtrosarchivos" v-model="formContrarreferencia.fileFactura" accept=".pdf" chips
                    outlined dense prepend-icon="" label="Seleccionar archivos"
                    style="min-width: 30rem; max-width: 30rem;" :error-messages="errors">
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <div class="d-flex">
                    <v-row>
                      <v-col v-for="(item, i) in formContrarreferencia.fileFactura" :key="i" cols="4"
                        class="mt-0 mb-0 pt-0 pb-0 me-2">
                        <v-btn small fab icon color="blue" @click="visualizarDocumentoSubido(item)">
                          <v-icon>visibility</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </validation-provider>
                <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                  <v-textarea style="width: 95%;" v-model="formContrarreferencia.observaciones" class="mt-3 ms-2"
                    rows="3" outlined label="Observaciones" :error-messages="errors">
                  </v-textarea>
                </validation-provider>
              </div>
              <div class="d-flex justify-end me-6">
                <v-btn class="me-3" text color="error" @click="cerrarDialogContrarreferencia()">Cerrar</v-btn>
                <v-btn class="me-2" depressed :disabled="invalid" color="success"
                  @click="guardarContrarreferencia()">Guardar
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LectorPdfRutas :dialogPdf="dialogPdf" :pdfUrl="pdfUrl" @cerrarDialogPdf="cerrarDialogPdf" />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LectorPdfRutas from '@/components/LectorPdfRutas.vue';
import { mapMutations } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LectorPdfRutas
  },
  props: {
    dialogContrarreferencia: Boolean,
    informacionFilaPrincipal: Object
  },
  data() {
    return {
      overlay: false,
      dialogPdf: false,
      pdfUrl: "",
      formContrarreferencia: {
        observaciones: "",
        fileResultados: [],
        fileFactura: []
      },
    }
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),

    guardarContrarreferencia() {
      const facturas = this.formContrarreferencia.fileFactura;
      const resultados = this.formContrarreferencia.fileResultados;
      if(facturas.length == 0 && resultados.length > 0){
        this.showError("Debe agregar una factura")
        return;
      }

      if(facturas.length > 0 && resultados.length == 0){
        this.showError("Debe agregar minimo un soporte")
        return;
      }

      this.overlay = true;
      const formData = new FormData();
      const observacion = JSON.stringify(this.formContrarreferencia.observaciones);
      formData.append('observacion', observacion);

      for(const file of this.formContrarreferencia.fileResultados) {
        formData.append('soporteAtencion', file);
      }

      formData.append('factura', this.formContrarreferencia.fileFactura);

      this.$http.post("msa-localapp/api/v1/rutas/contrareferencia/" + this.informacionFilaPrincipal.idSolicitud, formData, 
      { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
        this.overlay = false;
        this.showSuccess("La contrarreferencia se ha subido correctamente");
        this.cerrarDialogContrarreferencia();
      }).catch(err => {
        this.overlay = false;
        if (err.status == 500) this.showError('Error interno del servidor')
      })
    },

    visualizarDocumentoSubido(item) {
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = true;
      this.pdfUrl = URL.createObjectURL(item);
      this.dialogPdf = true;
      console.log(this.pdfUrl)
    },

    cerrarDialogContrarreferencia() {
      this.$emit('cerrarDialogContrarreferencia', false);
      this.formContrarreferencia.fileFactura = [];
      this.formContrarreferencia.fileResultados = [];
      this.formContrarreferencia.observaciones = '';
    },

    cerrarDialogPdf(objeto) {
      this.dialogPdf = objeto.dialogPdf;
      this.pdfUrl = "";
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = false;
    },
  },

  watch: {
    "formContrarreferencia.fileResultados": function () {
      console.log(this.formContrarreferencia.fileResultados)
    },
  },
}
</script>

<style scoped></style>