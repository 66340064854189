<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                              ###### -->
<!-- ###### @date: marzo 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
  <div>
    <v-dialog scrollable v-model="dialogSolicitud" persistent transition="dialog-bottom-transition" max-width="1000">
      <v-card> <!-- :style="{ height: this.activeForm == false ? '47rem' : '100%'}" -->
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Solicitud ambulatoria</v-toolbar-title>
            <v-btn icon dark @click="cerrarDialogSolicitud()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <!-- <v-card elevation="5" rounded outlined class="mt-2 ms-6 me-5"> -->
        <v-card-text class="mt-6">
          <v-form :disabled="activeForm" class="me-5 ms-5" v-model="formValidarSolicitud">
            <validation-observer v-slot="{ invalid }" ref="observerSolicitud">
              <div class="d-flex mt-3 mb-1 ">
                <validation-provider v-if="!activeForm" style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.tipoDocumento"
                    :items="listaTipoDocumento" return-object :item-text="mostrarListaTipoDocumentos" dense outlined :error-messages="errors" label="Tipo documento *">
                  </v-autocomplete>
                </validation-provider>
                <div v-if="activeForm" style="width: 25%;">
                  <v-text-field disabled style="width: 95%;" v-model="tipoDocumentoDetalle" dense outlined
                    label="Tipo documento">
                  </v-text-field>
                </div>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-text-field :disabled="(formNuevaSolicitud.tipoDocumento === '')" style="width: 95%;" v-model="formNuevaSolicitud.numeroDocumento" :counter="11"
                    :error-messages="errors" label="N° de documento *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <v-menu ref="menuFechaOrdenamiento" v-model="menuFechaOrdenamiento" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                      <v-text-field style="width: 95%;" v-model="formNuevaSolicitud.fechaOrdenamiento"
                        label="Fecha de ordenamiento *" :error-messages="errors" readonly v-bind="attrs" v-on="on" dense
                        outlined>
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker v-model="formNuevaSolicitud.fechaOrdenamiento" :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDateOrdenamiento">
                  </v-date-picker>
                </v-menu>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" v-model="formNuevaSolicitud.ips" :items="listaIps" dense outlined
                    :error-messages="errors" return-object :item-text="mostrarListaIps" label="IPS solicitante *">
                  </v-autocomplete>
                </validation-provider>
              </div>
              <div class="d-flex mb-1">
                <validation-provider ref="primerNombre" style="width: 25%;" v-slot="{ errors }"
                  :rules="{ required: true, }">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.primerNombre" :error-messages="errors"
                    label="Primer nombre *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <div style="width: 25%;">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.segundoNombre" label="Segundo nombre"
                    dense outlined>
                  </v-text-field>
                </div>
                <validation-provider ref="primerApellido" style="width: 25%;" v-slot="{ errors }"
                  :rules="{ required: true }">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.primerApellido" :error-messages="errors"
                    label="Primer apellido *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <div style="width: 25%;">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.segundoApellido"
                    label="Segundo apellido" dense outlined>
                  </v-text-field>
                </div>
              </div>
              <div class="d-flex mb-1">
                <validation-provider ref="celular" style="width: 25%;" v-slot="{ errors }" name="celular" :rules="{
      required: formNuevaSolicitud.numeroCelular == '' && formNuevaSolicitud.numeroTelefono == '' ? true : false,
    }">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.numeroCelular" :error-messages="errors"
                    :counter="10" label="Celular *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 25%;" v-slot="{ errors }" name="telefono" :rules="{
      required: formNuevaSolicitud.numeroCelular == '' && formNuevaSolicitud.numeroTelefono == '' ? true : false,
    }">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.numeroTelefono" :error-messages="errors"
                    :counter="10" label="Teléfono *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formNuevaSolicitud.municipio" :items="listaMunicipios"
                    dense outlined :error-messages="errors" return-object :item-text="mostrarMunicipios"
                    label="Municipio *">
                  </v-autocomplete>
                </validation-provider>
                <div v-if="!activeForm" style="width: 25%;">
                  <v-text-field disabled style="width: 95%;" v-model="formNuevaSolicitud.subRegion" dense
                    outlined label="Subregión">
                  </v-text-field>
                </div>
                <div v-if="activeForm" style="width: 25%;">
                  <v-text-field disabled style="width: 95%;" v-model="formNuevaSolicitud.subRegion" dense outlined
                    label="Subregión">
                  </v-text-field>
                </div>
              </div>
              <div class="d-flex mb-1">
                <validation-provider v-if="!activeForm" style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete @keyup="buscarGrupoCie($event.target.value)" style="width: 95%;" v-model="formNuevaSolicitud.grupoDiagnostico"
                    :items="listaGrupoDiagnostico" return-object :item-text="mostrarGrupoDiagnostico" dense outlined
                    :error-messages="errors" label="Grupo de diagnostico *">
                  </v-autocomplete>
                </validation-provider>
                <div v-if="activeForm" style="width: 25%;">
                  <v-text-field style="width: 95%;" v-model="detalleGrupoDiagnostico"
                     dense outlined label="Grupo de diagnostico *">
                  </v-text-field>
                </div>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" v-model="formNuevaSolicitud.especialidadRemitente"
                    :items="listaEspecialidades" return-object :item-text="mostrarEspecialidades" dense outlined
                    :error-messages="errors" label="Especialidad que remite *">
                  </v-autocomplete>
                </validation-provider>
                <div style="width: 25%;">
                  <v-autocomplete style="width: 95%;" :items="listaCohorte" disabled
                    v-model="formNuevaSolicitud.pacienteCohorte" dense outlined label="Paciente en cohorte">
                  </v-autocomplete>
                </div>
                <validation-provider v-if="!activeForm" style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" v-model="formNuevaSolicitud.codigoCups" :items="listaCups"
                    return-object :item-text="mostrarCodigoCups" multiple dense outlined :error-messages="errors"
                    label="Código CUPS *">
                  </v-autocomplete>
                </validation-provider>
              </div>

              <div v-if="!activeForm" style="margin-bottom: 3%;">
                <div v-if="formNuevaSolicitud.codigoCups != ''">
                  <div style="display: block;">
                    <strong>
                      <p class="mb-1">Llene la información de cada código</p>
                    </strong>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap; margin-bottom: 3%;">
                  <div v-for="(item, i) in formNuevaSolicitud.codigoCups" :key="i" class="me-2">
                    <v-btn small :color="item.estado == 0 ? 'error' : 'success'" @click="abrirDialogCups(item, i)">
                      {{ item.cups }}</v-btn>
                  </div>
                </div>
              </div>
              <section v-if="activeForm">
                <div>
                  <div style="display: block;">
                    <strong>
                      <p style="margin-bottom: 0; color: rgb(46, 41, 41);">Descripción del servicio</p>
                    </strong>
                    <strong>
                      <p>{{ detalleDescripcionCup }}</p>
                    </strong>
                  </div>
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      <strong>
                        <p style="margin-bottom: 1%; color: rgb(46, 41, 41);">¿El servicio solicitado está incluido en
                          el anexo de
                          prestaciones de la
                          Ruta?</p>
                      </strong>
                      <strong>
                        <p class="ms-2">{{ detalleIncluidoEnRuta }}</p>
                      </strong>
                    </div>
                    <div style="display: block; justify-content: center; margin-right: 10%;">
                      <div>
                        <strong>
                          <p class="mb-1" style="color: rgb(46, 41, 41);">¿La solicitud es prioritaria?</p>
                        </strong>
                      </div>
                      <div>
                        <strong>
                          <p class="ms-2">{{ detallePrioritario }}</p>
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div style="display: block;">
                    <strong>
                      <p style="margin-bottom: 1%; margin-top: 1%; color: rgb(46, 41, 41);">¿El servicio solicitado está
                        asociado o es
                        requerido para
                        diagnóstico o seguimiento de la ENFERMEDAD AUTOINMUNE?</p>
                    </strong>
                    <strong>
                      <p class="ms-2">{{ detalleAutoinmune }}</p>
                    </strong>
                  </div>
                </div>
              </section>
              <div>
                <div v-if="!activeForm" style="margin-right: 20%;">
                  <validation-provider class="d-flex" v-slot="{ errors }" :rules="{ required: true }">
                    <div style="display: flex; margin-right: 1rem; min-width: 13rem;" class="mt-2">
                      <strong class="me-1">Seleccione la historia clínica</strong>
                      <p style="color: red; display: inline;">*</p>
                    </div>

                    <v-file-input ref="refHistoriaClinica" v-model="formNuevaSolicitud.historiaClinicaPdf" accept=".pdf"
                      outlined dense prepend-icon="" label="Seleccionar archivo"
                      style="min-width: 30rem; max-width: 30rem;" :error-messages="errors">
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                    <div
                      v-if="formNuevaSolicitud.historiaClinicaPdf != null && formNuevaSolicitud.historiaClinicaPdf != ''">
                      <v-btn small fab icon color="blue"
                        @click="visualizarDocumentoSubido(formNuevaSolicitud.historiaClinicaPdf)"><v-icon>visibility</v-icon></v-btn>
                    </div>
                  </validation-provider>
                  <div class="d-flex">
                    <div class="d-flex mt-2" style="margin-right: 1.7rem; min-width: 12.3rem;">
                      <strong class="me-1">Seleccione la Orden médica</strong>
                    </div>
                    <v-file-input ref="refOrdenMedica" v-model="formNuevaSolicitud.ordenMedicaPDF" accept=".pdf"
                      outlined dense prepend-icon="" label="Seleccionar archivo"
                      style="min-width: 30rem; max-width: 30rem;">
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                    <div v-if="formNuevaSolicitud.ordenMedicaPDF != null && formNuevaSolicitud.ordenMedicaPDF != ''">
                      <v-btn small fab icon color="blue"
                        @click="visualizarDocumentoSubido(formNuevaSolicitud.ordenMedicaPDF)"><v-icon>visibility</v-icon></v-btn>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="d-flex mt-2" style="margin-right: 2.3rem;  min-width: 11.7rem;">
                      <strong class="me-1">Seleccione otros archivos</strong>
                    </div>
                    <v-file-input ref="refOtrosarchivos" v-model="formNuevaSolicitud.otrosArchivosPdf" accept=".pdf"
                      multiple chips outlined dense prepend-icon="" label="Seleccionar archivos"
                      style="min-width: 30rem; max-width: 30rem;">
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                    <div class="d-flex">
                      <v-row>
                        <v-col v-for="(item, i) in formNuevaSolicitud.otrosArchivosPdf" :key="i" cols="4"
                          class="mt-0 mb-0 pt-0 pb-0 me-2">
                          <v-btn small fab icon color="blue" @click="visualizarDocumentoSubido(item)">
                            <v-icon>visibility</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
                <div v-if="activeForm" style="margin-bottom: 1%; margin-top: 1%; color: rgb(46, 41, 41);">
                  <p><strong>Documentos adjuntos a la solicitud</strong></p>
                </div>
                <div v-if="activeForm" style="display: flex; flex-wrap: wrap;">
                  <div v-for="(item, i) in respuestaDetalle.anexo" :key="i">
                    <v-btn style="width: 10%;" :disabled="estadoSolicitud" :ref="item.idAnexo" small class="me-2 mb-2" :style="{
                        backgroundColor: colorBotonDocumento(item), color: 'white', fontSize: '0.8em',
                        width: '10rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                        }" :title="item.tipoAdjunto" @click="mostrarPdf(item, false)">{{ item.tipoAdjunto }}
                    </v-btn>
                  </div>
                </div>
                <div v-if="activeForm" style="margin-bottom: 1%; margin-top: 1%; color: rgb(46, 41, 41);">
                  <p><strong>Documentos adjuntos a la contrarrefrencia</strong></p>
                </div>
                <div v-if="activeForm" style="display: flex; flex-wrap: wrap;">
                  <div v-for="(item, i) in informacionContrarreferencia" :key="i">
                    <v-btn style="width: 10%;" :ref="item.idAnexo" small class="me-2 mb-2" :style="{
                        backgroundColor: colorBotonDocumento(item), color: 'white', fontSize: '0.8em',
                        width: '10rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                        }" :title="item.tipoAdjunto" @click="mostrarPdf(item, true)">{{ item.tipoAdjunto }}
                    </v-btn>
                  </div>
                </div>
                <div v-if="activeForm">
                  <div>
                    <strong>
                      <p class="mb-0 mt-3">Justificación de la solicitud (opcional)</p>
                    </strong>
                  </div>
                  <div style="margin-right: 20%;">
                    <v-textarea v-model="formNuevaSolicitud.justificacion" class="mt-3" rows="3" outlined
                      label="Justificación">
                    </v-textarea>
                  </div>
                </div>
                <div v-if="activeForm">
                  <strong>
                    <p class="mb-0 mt-3">Historial de las observaciones</p>
                  </strong>
                  <div>
                    <v-data-table :headers="headerAmbulatorio" :items="listaObservaciones" class="elevation mt-4"
                      loading-text="Cargando..."></v-data-table>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-end">
                <v-btn v-if="!activeForm" class="mt-6" depressed :disabled="invalid" color="success"
                  @click="guardarNuevaSolicitud()">Guardar </v-btn>
              </div>
            </validation-observer>
          </v-form>
        </v-card-text>
        <!-- </v-card> -->
      </v-card>
    </v-dialog>
    <LectorPdfRutas :dialogPdf="dialogPdf" :pdfUrl="pdfUrl" :idAnexo="idAnexo" :nombreArchivo="nombreArchivo"
      @cerrarDialogPdf="cerrarDialogPdf" @cambiarColorAprobado="cambiarColorAprobado"
      @cambiarColorRechazado="cambiarColorRechazado" :inhabilitarBotonesPdf="inhabilitarBotonesPdf" />
    <v-dialog scrollable v-model="dialogCups" max-width="850">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>{{
      tituloDialogCups
    }}</v-toolbar>
        <v-card-text>
          <validation-observer v-slot="{ invalid }" ref="observerCups">
            <v-form ref="formCups" class="mt-3" v-model="formValidarCups">
              <div>
                <div style="display: block;">
                  <strong>
                    <p style="margin-bottom: 0;">Descripción del servicio</p>
                  </strong>
                  <strong>
                    <p style="color: black;">{{formCups.descripcionServicio}}</p>
                  </strong>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <div>
                    <strong>
                      <p style="margin-bottom: 1%;">¿El servicio solicitado está incluido en el anexo de prestaciones de
                        la
                        Ruta?</p>
                    </strong>
                    <strong>
                      <p class="ms-2" style="color: black;">{{ detalleIncluidoEnRuta }}</p>
                    </strong>
                  </div>
                  <div style="display: block; justify-content: center; margin-right: 10%;">
                    <div>
                      <strong>
                        <p class="mb-1">¿La solicitud es prioritaria?</p>
                      </strong>
                    </div>
                    <div>
                      <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                        <v-autocomplete v-model="formCups.prioritario"
                          style="width: 82%; margin: 0; padding: 0; color: black;" :items="listaConfirmacion"
                          :error-messages="errors"></v-autocomplete>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div style="display: block;">
                  <strong>
                    <p style="margin-bottom: 1%; margin-top: 1%;">¿El servicio solicitado está asociado o es requerido
                      para
                      diagnóstico o seguimiento de la ENFERMEDAD AUTOINMUNE?</p>
                  </strong>
                  <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                    <v-autocomplete class="ms-2" v-model="formCups.estudioPorAutoinmune"
                      style="width: 20%; margin: 0; padding: 0; color: black;" :items="listaConfirmacion"
                      :error-messages="errors"></v-autocomplete>
                  </validation-provider>
                </div>
                <div>
                  <div>
                    <strong>
                      <p class="mb-0 mt-3">Justificación de la solicitud (opcional)</p>
                    </strong>
                  </div>
                  <div style="margin-right: 20%;">
                    <v-textarea v-model="formCups.justificacion" class="mt-3" rows="3" outlined label="Justificación">
                    </v-textarea>
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: end;">
                <v-btn @click="cerrarDialogCups()" class="me-3 mb-3" text color="error">Cerrar</v-btn>
                <v-btn :disabled="invalid" class="me-2 mb-3" depressed @click="guardarCups()" color="success">
                  Guardar </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogNuevoArchivo" max-width="850">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Formulario corrección</v-toolbar-title>
            <v-btn icon dark @click="cerrarDialogNuevoArchivo()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text>
          <validation-observer v-slot="{ invalid }" ref="observerNuevoArchivo">
            <v-form class="mt-3">
              <div>
                <validation-provider v-slot="{ errors }" :rules="{ required: true },">
                  <v-file-input v-model="fileNuevoArchivo" accept=".pdf" outlined dense prepend-icon=""
                    label="Seleccione el archivo de reemplazo" :error-messages="errors">
                  </v-file-input>
                </validation-provider>
              </div>
              <div style="display: flex; justify-content: end;">
                <v-btn :disabled="invalid" depressed class="me-2 mb-3" @click="guardarNuevoArchivo()" color="success">
                  Guardar </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, regex, digits, min, integer } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
import LectorPdfRutas from '@/components/LectorPdfRutas.vue';
import { mapMutations, mapState } from "vuex";
import moment from 'moment';

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'Este campo es requerido.',
});

extend('min', {
  ...min,
  message: 'Dirección erronea',
});

extend('digits', {
  ...digits,
  message: 'Número Incorrecto.',
});

extend('regex', {
  ...regex,
  message: 'Formato Incorrecto',
});

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LectorPdfRutas,
  },
  props: {
    dialogSolicitud: Boolean,
    activeForm: Boolean,
    activeEdit: Boolean,
    mensajePdf: String,
    respuestaDetalle: Object,
    informacionContrarreferencia: Array,
    listaIps: Array,
    listaGrupoDiagnostico: Array,
    listaCups: Array,
    listaMunicipios: Array,
    listaEspecialidades: Array,
    listaTipoDocumento: Array,
    tipoSolicitudRequerida: integer,
    idRuta: integer,
    userRoles: Array,
    listaObservaciones: Array,
    estadoSolicitud: Boolean
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
  },

  data() {
    return {
      dialogCups: false,
      dialogNuevoArchivo: false,
      tituloDialogCups: "",
      menuFechaSolicitud: false,
      menuFechaOrdenamiento: false,
      menuFechaGestion: false,
      menuFechaCita: false,
      menuHora: false,
      activePicker: null,
      formValidarSolicitud: true,
      formValidarCups: true,
      habilitarCamposAfiliados: false,
      visiblePdf: true,
      inhabilitarBotonesPdf: null,
      dialogPdf: false,
      pdfUrl: null,
      nombreColorRef: "",
      nombreColorRefPdf: "",
      fileNuevoArchivo: null,
      idAnexo: "",
      nombreArchivo: "",
      indexCup: null,
      informacionDetalleCups: {},
      formNuevaSolicitud: {
        fechaOrdenamiento: "",
        ips: "",
        tipoDocumento: "",
        idAfiliado: "",
        numeroDocumento: "",
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        numeroCelular: "",
        numeroTelefono: "",
        municipio: "",
        subRegion: "",
        grupoDiagnostico: "",
        especialidadRemitente: "",
        pacienteCohorte: "",
        codigoCups: [],
        historiaClinicaPdf: [],
        ordenMedicaPDF: [],
        otrosArchivosPdf: [],
        justificacion: ""
      },
      formData: new FormData(),
      formCups: {
        descripcionServicio: "",
        incluidoEnRuta: null,
        prioritario: null,
        estudioPorAutoinmune: null,
        justificacion: ""
      },
      tipoDocumentoDetalle: "",
      detallePrioritario: "",
      detalleAutoinmune: "",
      detalleDescripcionCup: "",
      detalleIncluidoEnRuta: "",
      detalleGrupoDiagnostico: "",
      codigoTipoDocumento: "",
      headerAmbulatorio: [
        { text: "ESTADO", value: "estadoSeguimiento", align: "ips", width: "15%", sortable: false },
        { text: "PRESTADOR", value: "nombrePrestador", align: "center", width: "10%", sortable: false },
        { text: "FECHA CITA", value: "fechaCita", align: "center", width: "15%", sortable: false },
        { text: "HORA CITA", value: "horaCita", align: "center", width: "20%", sortable: false },
        { text: "OBSERVACIÓN", value: "observacion", align: "center", width: "40%", sortable: false },
        { text: "USUARIO", value: "usuario", align: "center", width: "40%", sortable: false },
      ],
      headerContrareferencia: [
        { text: "OBSERVACIÓN", value: "observacion", align: "ips", width: "15%", sortable: false },
        { text: "RESULTADOS", value: "ruta_archivo", align: "center", width: "10%", sortable: false },
        { text: "FACTURA", value: "ruta_archivo", align: "center", width: "10%", sortable: false },
      ],
      respuestaValidarAfiliado: [],
      listaCodigosCups: [],
      listaCohorte: [
        { text: "SI", value: true },
        { text: "NO", value: false }],
      listaConfirmacion: [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],
      objetoFinal: {
        encabezado: {
          idAfiliado: null,
          idEmpresa: null,
          fechaSolicitud: "",
          fechaOrdenamiento: "",
          ips: "",
          tipoDocumento: "",
          numeroDocumento: "",
          primerNombre: "",
          segundoNombre: "",
          primerApellido: "",
          segundoApellido: "",
          numeroCelular: "",
          numeroTelefono: "",
          municipio: "",
          subRegion: "",
          grupoDiagnostico: "",
          especialidadRemitente: "",
          tipoSolicitud: null,
          idRuta: null,
          cohorte: null
        },
        cuerpo: [],
      }
    }
  },

  mounted() {
    this.$on('llenarCamposDetalles', respuestaDetalle => {
      console.log("lista grupo cie")
      console.log(this.listaGrupoDiagnostico)
      console.log("respuesta detalle")
      console.log(respuestaDetalle)
      this.formNuevaSolicitud.fechaOrdenamiento = moment.utc(respuestaDetalle.fechaHistoria).format('YYYY-MM-DD'),
      this.formNuevaSolicitud.numeroDocumento = respuestaDetalle.numeroDocumento,
      this.tipoDocumentoDetalle = respuestaDetalle.tipoDocumento,
      this.formNuevaSolicitud.municipio = respuestaDetalle.ciudad,
      this.formNuevaSolicitud.subRegion = respuestaDetalle.subregion,
      this.detalleGrupoDiagnostico = respuestaDetalle.descripcionGrupo,
      this.formNuevaSolicitud.codigoCups.push(respuestaDetalle.cups),
      this.detalleDescripcionCup = respuestaDetalle.cups + ' - ' + respuestaDetalle.descripcionCups,
      this.formNuevaSolicitud.especialidadRemitente = respuestaDetalle.descripcionEspecialidad,
      this.formNuevaSolicitud.ips = respuestaDetalle.nombrePrestador,
      this.detallePrioritario = respuestaDetalle.prioritario === true ? 'SI' : 'NO';
      this.detalleAutoinmune = respuestaDetalle.atencionAutoinmune === true ? 'SI' : 'NO';
      this.detalleIncluidoEnRuta = respuestaDetalle.incluidoRuta === true ? 'SI' : 'NO';
      this.formNuevaSolicitud.justificacion = respuestaDetalle.justificacion
      this.codigoTipoDocumento = respuestaDetalle.codigoTipoDocumento;
    })
  },

  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),

    cambiarEstadoCup() {
      this.formNuevaSolicitud.codigoCups[this.indexCup].estado = 1;
    },

    validarAfiliado(item) {
      console.log(this.codigoTipoDocumento)
      if(this.formNuevaSolicitud.tipoDocumento.tipoDocumento != '' && item != '' && this.formNuevaSolicitud.tipoDocumento != null && item != null){
        let tipoDocumento;
        if(this.formNuevaSolicitud.tipoDocumento.tipoDocumento != null){
          tipoDocumento = this.formNuevaSolicitud.tipoDocumento.tipoDocumento;
        }else{
          tipoDocumento = this.codigoTipoDocumento;
        }
        return new Promise((resolve) => {
          let numeroDocumento = item != '' ? item : 'null'
          this.$http.get("msa-localapp/api/v1/afiliado/" + tipoDocumento + '/' + numeroDocumento).then((result) => {
            this.respuestaValidarAfiliado = result.data
            console.log(this.respuestaValidarAfiliado)
            resolve(true)
          }).catch(() => {
            resolve(false)
          })
        })
      }
    },

    async llenarCamposAfiliado() {
      await this.validarAfiliado(this.formNuevaSolicitud.numeroDocumento)
      if (this.respuestaValidarAfiliado != null) {
        console.log(this.respuestaValidarAfiliado)
        this.habilitarCamposAfiliados = true;
        this.formNuevaSolicitud.idAfiliado = this.respuestaValidarAfiliado?.idAfiliado;
        this.formNuevaSolicitud.tipoDocumento = this.respuestaValidarAfiliado?.descripcion;
        this.formNuevaSolicitud.primerNombre = this.respuestaValidarAfiliado?.primerNombre;
        this.formNuevaSolicitud.segundoNombre = this.respuestaValidarAfiliado?.segundoNombre;
        this.formNuevaSolicitud.primerApellido = this.respuestaValidarAfiliado?.primerApellido;
        this.formNuevaSolicitud.segundoApellido = this.respuestaValidarAfiliado?.segundoApellido;
        this.formNuevaSolicitud.numeroCelular = this.respuestaValidarAfiliado?.numeroCelular;
        this.formNuevaSolicitud.numeroTelefono = this.respuestaValidarAfiliado?.numeroTelefono;
        this.formNuevaSolicitud.municipio = this.respuestaValidarAfiliado?.ciudad;
        this.formNuevaSolicitud.subRegion = this.respuestaValidarAfiliado?.subRegion;
        this.formNuevaSolicitud.pacienteCohorte = this.respuestaValidarAfiliado?.cohorte;
      } else {
        this.habilitarCamposAfiliados = false;
        this.formNuevaSolicitud.idAfiliado = "";
        this.formNuevaSolicitud.primerNombre = "";
        this.formNuevaSolicitud.segundoNombre = "";
        this.formNuevaSolicitud.primerApellido = "";
        this.formNuevaSolicitud.segundoApellido = "";
        this.formNuevaSolicitud.numeroCelular = "";
        this.formNuevaSolicitud.numeroTelefono = "";
        this.formNuevaSolicitud.municipio = "";
        this.formNuevaSolicitud.subRegion = "";
        this.formNuevaSolicitud.pacienteCohorte = false;
      }

    },
    cargarArchivosPDF() {
      return new Promise((resolve) => {
        if (this.formNuevaSolicitud.historiaClinicaPdf != null) {
          this.formData.append('historiaClinica', this.formNuevaSolicitud.historiaClinicaPdf);
        }
        if (this.formNuevaSolicitud.ordenMedicaPDF != null) {
          this.formData.append('ordenMedica', this.formNuevaSolicitud.ordenMedicaPDF);
        }
        for (let i = 0; i < this.formNuevaSolicitud.otrosArchivosPdf.length; i++) {
          if (this.formNuevaSolicitud.otrosArchivosPdf[i] != null) {
            this.formData.append('otrosArchivos', this.formNuevaSolicitud.otrosArchivosPdf[i]);
          }
        }
        resolve(true);
      })
    },

    modificarArray() {
      this.objetoFinal.encabezado.idAfiliado = this.formNuevaSolicitud.idAfiliado,
      this.objetoFinal.encabezado.idEmpresa = this.enterprise.code,
      this.objetoFinal.encabezado.fechaSolicitud = moment(new Date()).format('YYYY-MM-DD'),
      this.objetoFinal.encabezado.fechaOrdenamiento = moment(this.formNuevaSolicitud.fechaOrdenamiento).format('YYYY-MM-DD'),
      this.objetoFinal.encabezado.ips = this.formNuevaSolicitud.ips.id_prestador,
      this.objetoFinal.encabezado.tipoDocumento = this.respuestaValidarAfiliado == null ? this.formNuevaSolicitud.tipoDocumento.tipoDocumento : this.respuestaValidarAfiliado?.tipoDocumento,
      this.objetoFinal.encabezado.numeroDocumento = this.formNuevaSolicitud.numeroDocumento,
      this.objetoFinal.encabezado.primerNombre = this.formNuevaSolicitud.primerNombre,
      this.objetoFinal.encabezado.segundoNombre = this.formNuevaSolicitud.segundoNombre,
      this.objetoFinal.encabezado.primerApellido = this.formNuevaSolicitud.primerApellido,
      this.objetoFinal.encabezado.segundoApellido = this.formNuevaSolicitud.segundoApellido,
      this.objetoFinal.encabezado.numeroCelular = this.formNuevaSolicitud.numeroCelular,
      this.objetoFinal.encabezado.numeroTelefono = this.formNuevaSolicitud.numeroTelefono,
      this.objetoFinal.encabezado.municipio = this.respuestaValidarAfiliado == null ? this.formNuevaSolicitud.municipio.id_ciudad : this.respuestaValidarAfiliado.idCiudad ,
      this.objetoFinal.encabezado.subRegion = this.respuestaValidarAfiliado == null ? this.formNuevaSolicitud.municipio.id_subregion : this.respuestaValidarAfiliado.idSubRegion,
      this.objetoFinal.encabezado.grupoDiagnostico = this.formNuevaSolicitud.grupoDiagnostico.igrupo_cie,
      this.objetoFinal.encabezado.especialidadRemitente = this.formNuevaSolicitud.especialidadRemitente.id_especialidad,
      this.objetoFinal.encabezado.tipoSolicitud = this.tipoSolicitudRequerida,
      this.objetoFinal.encabezado.cohorte = this.formNuevaSolicitud.pacienteCohorte
      this.objetoFinal.encabezado.idRuta = this.idRuta

      const cupElejido = this.informacionDetalleCups
      const arrayFinal = this.objetoFinal.cuerpo
      if (this.objetoFinal.cuerpo.length == 0) {
        this.objetoFinal.cuerpo.push({
          cup: cupElejido.cups,
          incluidoRuta: cupElejido.incluido_en_ruta,
          prioritario: this.formCups.prioritario,
          autoinmune: this.formCups.estudioPorAutoinmune,
          justificacion: this.formCups.justificacion
        })
      } else {
        const cupBuscado = arrayFinal.find(arrayFinal => arrayFinal.cup === cupElejido.cups)
        if (cupBuscado) {
          Object.assign(cupBuscado, {
            cup: cupElejido.cups,
            incluidoRuta: cupElejido.incluido_en_ruta,
            prioritario: this.formCups.prioritario,
            autoinmune: this.formCups.estudioPorAutoinmune,
            justificacion: this.formCups.justificacion
          });
        } else {
          this.objetoFinal.cuerpo.push({
            cup: cupElejido.cups,
            incluidoRuta: cupElejido.incluido_en_ruta,
            prioritario: this.formCups.prioritario,
            autoinmune: this.formCups.estudioPorAutoinmune,
            justificacion: this.formCups.justificacion
          })

        }
      }
      console.log(this.objetoFinal)
      return this.objetoFinal
    },

    async guardarNuevaSolicitud() {
      if (this.formNuevaSolicitud.codigoCups.length == this.objetoFinal.cuerpo.length) {
        await this.cargarArchivosPDF();
        let obj = JSON.stringify(this.objetoFinal);
        this.formData.append('objetoFinal', new Blob([obj], { type: 'application/json' }));
        this.$http.post("msa-localapp/api/v1/rutas/ambulatoria", this.formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
            this.showSuccess("Solictud creada correctamente");
            this.cerrarDialogSolicitud();
            this.$emit('getListaSolicitudes');
          }).catch((error) => {
            console.log("aqui el errorrrrrrrrrrrrrrrr")
            console.log(error.response.data.message)
            this.showError(error.response.data.message[0])
          })
      } else {
        this.showError("Debe diligenciar todos los codigos CUPS");
      }
    },

    obtenerPDF(item) {
      return new Promise((resolve, reject) => {
        this.$http.get("msa-localapp/api/v1/rutas/anexo/download/" + item.idAnexo, {
          responseType: 'blob',
        })
          .then((response) => {
            if (response.status === 200) {
              const pdfData = URL.createObjectURL(response.data);
              resolve(pdfData);
            } else {
              reject(new Error('Error al obtener el PDF'));
            }
          })
          .catch((error) => {
            console.log('Error al descargar el PDF:', error);
            reject(error);
          });
      });
    },

    async mostrarPdf(item, estado) {
      if(estado == true){
        this.inhabilitarBotonesPdf = true;
      }
      if(estado == false){
        this.inhabilitarBotonesPdf = false;
      }
      if (this.userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoSolicitante') && item.estado == 'Rechazado') {
        this.idAnexo = item.idAnexo;
        this.dialogNuevoArchivo = true;
        this.nombreColorRefPdf = item.idAnexo;
      } else {
        this.pdfUrl = await this.obtenerPDF(item);
        this.pageNum = 1;
        this.numPaginas = 0;
        this.nombreColorRefPdf = item.idAnexo;
        this.idAnexo = item.idAnexo;
        this.nombreArchivo = item.nombreArchivo;
        this.dialogPdf = true;
      }
    },

    visualizarDocumentoSubido(item) {
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = true;
      this.pdfUrl = URL.createObjectURL(item);
      this.dialogPdf = true;
    },


    cerrarDialogNuevoArchivo() {
      this.dialogNuevoArchivo = false;
      this.fileNuevoArchivo = null;
      this.$refs.observerNuevoArchivo.reset();
    },


    guardarNuevoArchivo() {
      const formData = new FormData();
      formData.append('file', this.fileNuevoArchivo);
      this.$http.put("msa-localapp/api/v1/rutas/anexo/" + this.idAnexo, formData, 
      { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
        this.showSuccess("El archivo se ha reemplazado correctamente.")
        const nombreRef = this.$refs[this.nombreColorRefPdf];
        if (nombreRef) {
          nombreRef[0].$el.style.backgroundColor = 'orange'
          this.cerrarDialogNuevoArchivo()
        }
      }).catch(() => {
        this.showError("Error inesperado al reemplazar el archivo")
      } 
    )
    },

    buscarGrupoCie(param){
      this.$emit('delayGrupoDiagnostico', param);
    },

    cerrarDialogPdf(objeto) {
      this.dialogPdf = objeto.dialogPdf;
      this.pdfUrl = "";
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = null;
    },

    cerrarDialogSolicitud() {
      this.limpiarFormulario();
      this.actualizarDialogSolicitud()
      this.visiblePdf = false;
      this.objetoFinal.encabezado = {};
      this.objetoFinal.cuerpo = [];
      this.formData = new FormData();
    },

    abrirDialogCups(item, i) {
      this.indexCup = i;
      this.informacionDetalleCups = item;
      const arrayFinal = this.objetoFinal.cuerpo
      const cupElejido = this.informacionDetalleCups
      if (arrayFinal.length > 0) {
        const cupBuscado = arrayFinal.find(arrayFinal => arrayFinal.cup === cupElejido.cups)
        if (cupBuscado) {
          this.formCups.incluidoEnRuta = cupElejido.incluidoRuta;
          this.formCups.prioritario = cupBuscado.prioritario;
          this.formCups.estudioPorAutoinmune = cupBuscado.autoinmune;
          this.formCups.justificacion = cupBuscado.justificacion;
        } else {
          console.log("no se encontro")
        }
      }
      this.nombreColorRef = item.cups
      this.formCups.descripcionServicio = item.descripcion_cups
      this.formCups.incluidoEnRuta = item.incluido_en_ruta
      if (item.incluido_en_ruta == true) {
        this.detalleIncluidoEnRuta = "SI";
      } else {
        this.detalleIncluidoEnRuta = "NO";
      }
      this.tituloDialogCups = "Formulario para el codigo: " + item.cups
      this.dialogCups = true;
    },

    cerrarDialogCups() {
      this.dialogCups = false;
      this.formCups.incluidoEnRuta = "";
      this.formCups.prioritario = "";
      this.formCups.estudioPorAutoinmune = "";
      this.formCups.justificacion = "";
      this.$refs.observerCups.reset();
    },

    guardarCups() {
      this.modificarArray();
      this.cambiarColor();
      this.cambiarEstadoCup();
      this.dialogCups = false;
      this.cerrarDialogCups();
    },

    cambiarColor() {
      const nombreRef = this.$refs[this.nombreColorRef];
      if (nombreRef) {
        nombreRef[0].$el.style.backgroundColor = 'rgba(76,175,80,255)';
      }
    },

    colorBotonDocumento(item) {
      if(item.estado == 'Pendiente'){
        return 'blue';
      }
      if(item.estado == 'Rechazado'){
        return 'rgba(255, 82, 82, 1)';
      }
      if(item.estado == 'Aceptado'){
        return 'rgba(76,175,80,255)'
      }
      if(item.estado == "Remplazado"){
        return 'orange'
      }
    },

    cambiarColorAprobado() {
      const nombreRef = this.$refs[this.nombreColorRefPdf];
      nombreRef[0].$el.style.backgroundColor = 'rgba(76,175,80,255)'
      this.$emit('getListaSolicitudes');
    },
    cambiarColorRechazado() {
      const nombreRef = this.$refs[this.nombreColorRefPdf];
      nombreRef[0].$el.style.backgroundColor = 'rgba(255, 82, 82, 1)'
      this.$emit('getListaSolicitudes');
    },

    limpiarFormulario() {
      this.formNuevaSolicitud.fechaOrdenamiento = "";
      this.formNuevaSolicitud.ips = "";
      this.formNuevaSolicitud.tipoDocumento = "";
      this.formNuevaSolicitud.numeroDocumento = "";
      this.formNuevaSolicitud.primerNombre = "";
      this.formNuevaSolicitud.segundoNombre = "";
      this.formNuevaSolicitud.primerApellido = "";
      this.formNuevaSolicitud.segundoApellido = "";
      this.formNuevaSolicitud.numeroCelular = "";
      this.formNuevaSolicitud.numeroTelefono = "";
      this.formNuevaSolicitud.municipio = "";
      this.formNuevaSolicitud.subRegion = "";
      this.formNuevaSolicitud.grupoDiagnostico = "";
      this.formNuevaSolicitud.especialidadRemitente = "";
      this.formNuevaSolicitud.codigoCups = [];
      this.formNuevaSolicitud.historiaClinicaPdf = null;
      this.formNuevaSolicitud.ordenMedicaPDF = null;
      this.formNuevaSolicitud.otrosArchivosPdf = [];
      this.formNuevaSolicitud.justificacion = "";
      this.habilitarCamposAfiliados = false;
      this.codigoTipoDocumento = '';
      this.respuestaValidarAfiliado = null;
      this.$refs.observerSolicitud.reset();
    },
    actualizarDialogSolicitud() {
      // Emitir un evento al componente padre para actualizar la prop
      this.$emit('cerrarDialogSolicitud', { dialogSolicitud: false, tipoSolicitudRequerida: null });
    },
    mostrarListaIps(item) {
      return item?.nombre_prestador;
    },
    mostrarListaTipoDocumentos(item) {
      return item?.descripcion
    },
    mostrarGrupoDiagnostico(item) {
      return item?.igrupo_cie + ' - ' + item?.idescripcion_grupo;
    },
    mostrarEspecialidades(item) {
      return item?.descripcion_especialidad
    },
    mostrarMunicipios(item) {
      return item?.ciudad
    },
    mostrarCodigoCups(item) {
      return item?.cups + " - " + item.descripcion_cups
    },

    saveDateSolicitud(date) {
      this.$refs.menuFechaSolicitud.save(date)
    },
    saveDateOrdenamiento(date) {
      this.$refs.menuFechaOrdenamiento.save(date)
    },

  },
  watch: {
    // "itemsPorPagina": function () { this.getRelacionesHerinco(); },

    "formNuevaSolicitud.numeroDocumento": debounce(function () {
      this.llenarCamposAfiliado();
    }, 500),
    "formNuevaSolicitud.municipio": function () {
      if(this.respuestaValidarAfiliado != null){
        this.formNuevaSolicitud.subRegion = this.respuestaValidarAfiliado?.subRegion
      }else{
        this.formNuevaSolicitud.subRegion = this.formNuevaSolicitud.municipio?.subregion
      }
    }, 

    'formNuevaSolicitud.codigoCups': {
      handler() { this.$forceUpdate();},
      
      // deep: true,
    },

  },
}
</script>
<style scoped>
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

::v-deep .v-input--is-disabled div div div input {
  color: rgb(56, 48, 48);
}

::v-deep .v-form div div div div div textarea {
  color: rgb(56, 48, 48);
}
</style>