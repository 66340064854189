<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                              ###### -->
<!-- ###### @date: marzo 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
    <div>
        <v-dialog persistent v-model="dialogPdf" max-width="850">
            <v-card>
                <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
                    <div style="display: flex; justify-content: space-between; width: 100%;  ">
                        <v-toolbar-title class="mt-2"></v-toolbar-title>
                        <v-btn icon dark @click="cerrarDialogPdf()">
                            <v-icon>close</v-icon>
                        </v-btn> 
                    </div>
                </v-toolbar>
                <v-card-text>
                    <pdf :src="pdfUrl" :page="pageNum" @num-pages="actualizarNumPaginas"></pdf>
                </v-card-text>
                <div style="display: flex; justify-content: end;">
                    <div v-if="(userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno') && inhabilitarBotonesPdf == false)" style="display: flex; justify-content: end;  width: 65%;">
                        <v-btn @click="abrirDialogRechazo()" class="me-3 mb-3" dense outlined small
                        text color="error"> Rechazar
                        </v-btn>
                        <v-btn @click="aprobarPdf()" class="me-3 mb-3" dense outlined small
                        color="success">Aprobar</v-btn>
                    </div>
                    <div>
                        <v-btn @click="descargarPDF()" class="me-3 mb-3" dense outlined small
                        color="success">Descargar</v-btn>
                    </div>
                    <div style="display: flex; justify-content: end; width: 35%;">
                        <v-tooltip top color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="paginaAnterior()" :disabled="pageNum <= 1" class="me-1 pe-1 mb-3" small
                                text color="success"  v-bind="attrs" v-on="on">
                                    <v-icon> arrow_back_ios </v-icon>
                                </v-btn>
                            </template>
                            <span>Pagina anterior</span>
                        </v-tooltip>
                        <v-tooltip top color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="paginaSiguiente()" :disabled="pageNum >= numPaginas" small class="me-0 ms-1 ps-1 mb-3" text
                                color="success" v-bind="attrs" v-on="on">
                                    <v-icon> arrow_forward_ios </v-icon>
                                </v-btn>
                            </template>
                            <span>Pagina siguiente</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogJustificacionRechazo" max-width="500">
            <v-card>
                <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
                    <div style="display: flex; justify-content: space-between; width: 100%;  ">
                        <v-toolbar-title class="mt-2">Justificación</v-toolbar-title>
                        <v-btn icon dark @click="cerrarDialogRechazo()">
                            <v-icon>close</v-icon>
                        </v-btn> 
                    </div>
                </v-toolbar>
                <v-form class="me-5 ms-5" v-model="formJustificacionRechazo">
                    <validation-observer v-slot="{ invalid }" ref="observerDialogJustificacion">
                        <v-card-text class="pb-0">
                            <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                <v-autocomplete v-model="justificacionRechazo" outlined :items="listaJustificacionRechazo"
                                label="Seleccione la justificación del rechazo"
                                :error-messages="errors" class="mt-3"></v-autocomplete>
                            </validation-provider>
                            <validation-provider v-if="justificacionRechazo == 'Otro'" v-slot="{ errors }" :rules="{ required: true }">
                                <v-text-field v-model="justificacionRechazoOpcional" label="Ingrese la justificación del rechazo"
                                :error-messages="errors" outlined dense class="mt-3"></v-text-field>
                            </validation-provider>
                        </v-card-text>
                        <div style="display: flex; justify-content: end;">
                            <v-btn @click="rechazarPdf()" class="me-4 mb-3" :disabled="invalid"
                            depressed color="success"> Guardar
                            </v-btn>
                        </div>
                    </validation-observer>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import pdf from 'vue-pdf';
import {mapMutations, mapState } from "vuex";
import { ValidationObserver, ValidationProvider, extend, setInteractionMode } from 'vee-validate';
import { required, integer } from 'vee-validate/dist/rules';

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'Este campo es requerido.',
});

export default {
    data() {
        return {
            pageNum: 1,
            numPaginas: 0,
            formJustificacionRechazo: true,
            dialogJustificacionRechazo: false,
            justificacionRechazo: "",
            justificacionRechazoOpcional: "",
            userRoles:{},
            listaJustificacionRechazo:[
                { text: "CUP erróneo", value: 'CUP erroneo' },
                { text: "Documento no corresponde al usuario", value: "Documento no corresponde al usuario" },
                { text: "Orden incompleta", value: "Orden incompleta" },
                { text: "Error en la cantidad", value: "Error en la cantidad" },
                { text: "Otro", value: "Otro" }
            ]
        }
    },
    components: {
        pdf,
        ValidationProvider,
        ValidationObserver,
    },
    props:{
        pdfUrl: String,
        dialogPdf: Boolean,
        inhabilitarBotonesPdf: Boolean,
        idAnexo: integer,
        nombreArchivo: String
    },
    computed: {
    ...mapState(["auth", "enterprise"]),
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    methods: {
        ...mapMutations([
            "showSuccess",
            "showError"
        ]),

        paginaAnterior() {
            if (this.pageNum > 1) {
            this.pageNum--;
            }
        },
        paginaSiguiente() {
            if (this.pageNum < this.numPaginas) {
            this.pageNum++;
            }
        },

        actualizarNumPaginas(total) {
            this.numPaginas = total;
        },

        aprobarPdf(){
            this.$http.put("msa-localapp/api/editarEstadoAnexo", {
                idAnexo: this.idAnexo,
                estado: 2
            }).then((result) => {
                this.$emit('cambiarColorAprobado');
                    this.cerrarDialogPdf();
                    this.showSuccess(result.data)
            }).catch((e) => {
                console.log(e)
            })
            this.cerrarDialogPdf()
        },

        abrirDialogRechazo(){
            this.dialogJustificacionRechazo = true
        },

        cerrarDialogRechazo(){
            this.dialogJustificacionRechazo = false;
            this.justificacionRechazo = "";
            this.$refs.observerDialogJustificacion.reset();
        },

        rechazarPdf(){
            this.$http.put("msa-localapp/api/editarEstadoAnexo", {
                idAnexo: this.idAnexo,
                estado: 1,
                justificacion: this.justificacionRechazo == 'Otro' ? this.justificacionRechazoOpcional : this.justificacionRechazo
            }).then((result) => {
                    this.$emit('cambiarColorRechazado');
                    this.cerrarDialogPdf();
                    this.showSuccess(result.data);
            }).catch((e) => {
                console.log(e);
            })
            this.cerrarDialogRechazo();
            this.cerrarDialogPdf();
        },

        descargarPDF() {
            const link = document.createElement('a');
            link.href = this.pdfUrl;
            link.setAttribute('download', this.nombreArchivo); 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.cerrarDialogPdf()
        },

        cerrarDialogPdf() {
            this.$emit('cerrarDialogPdf', {dialogPdf: false});
        },
    },
}
</script>