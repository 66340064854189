<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                              ###### -->
<!-- ###### @date: marzo 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
    <div>
        <v-dialog v-model="dialogAnulacionRutas" persistent max-width="360">
          <v-card>
            <v-card-title style="background-color: #1867c0 !important;color: white;">
              ¿Desea anular la solicitud?
            </v-card-title>
              <div class="d-flex justify-end pa-3">
                <v-btn color="success" @click="anularSolicitud()"> Si </v-btn>
                <v-btn color="error" text @click="cerrarDialogConfirmacion()"> No </v-btn>
              </div>
          </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
import { integer } from 'vee-validate/dist/rules';
import {mapMutations } from "vuex";

export default {
    props: {
        dialogAnulacionRutas: Boolean,
        idSolicitudAnulada: integer
    },
  
    data() {
      return {
      };
    },

    methods: {

        ...mapMutations([
            "showSuccess",
            "showError"
        ]),

        anularSolicitud(){
            this.$http.delete("msa-localapp/api/v1/rutas/eliminar-solicitud/" + this.idSolicitudAnulada).then(() => {
                this.showSuccess("La solicitud ha sido anulada")
                this.$emit('getListaSolicitudes');
                this.$emit('cerrarDialogAnulacionRutas');
            }).catch(() => {
                this.showError("Error inesperado al anular la solicitud")
                this.$emit('getListaSolicitudes');
                this.$emit('cerrarDialogAnulacionRutas');
            })
        },

        cerrarDialogConfirmacion(){
            this.$emit('cerrarDialogAnulacionRutas');
        }
    },

};
  </script>
  
  <style scoped>
  </style>
  